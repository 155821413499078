$(function () {
	$('.slider').slick({
		autoplay: true, // 自動でスクロール
		autoplaySpeed: 0,
		speed: 20000,
		cssEase: "linear",
		slidesToShow: 1.5,
		swipe: false,
		arrows: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		responsive: [{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			}
		}]
	});
});
